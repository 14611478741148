import { Component, signal, WritableSignal } from '@angular/core';
import { LocationService } from "../administration/location/location.service";
import { CustomerService } from "./customer.service";
import { LoadingState } from "../shared/loading-state.enum";
import { WeekdayService } from "../administration/weekday/weekday.service";
import { ConfirmationService, FilterMetadata, MessageService } from "primeng/api";
import { Router } from "@angular/router";
import { Status, statuses, StatusModel } from "./add-edit-customer/status-form/status.model";
import { format, subYears } from "date-fns";
import { TableLazyLoadEvent } from "primeng/table";
import { Customer } from "./customer.model";
import { AuthService } from "../login/auth.service";
import { RoleEnum } from "../login/role.enum";
import { CharacteristicService } from "../administration/characteristic/characteristic.service";

@Component({
  selector: 'app-customer-overview',
  templateUrl: './customer-overview.component.html',
  styleUrls: ['./customer-overview.component.scss'],
  providers: [ConfirmationService]
})
export class CustomerOverviewComponent {
  selectedLocations = [];
  selectedWeekdays = [];
  selectedCharacteristics = [];
  bornAfter: Date = subYears(new Date(), 6);
  bornBefore: Date = new Date();
  ownerBornAfter: Date = new Date(1900, 0, 1);
  ownerBornBefore: Date = subYears(new Date(), 65);
  activateDate = false;
  activateOwnerDate = false;
  householdFilter = false;
  statusChange = false;
  minHousehold: number | null = null;
  maxHousehold: number | null = null;
  LoadingState = LoadingState;
  lastTableLazyLoadEvent: TableLazyLoadEvent | undefined;
  singleParent = false;
  missingFutureLocation = false;
  customers: WritableSignal<Customer[]> = signal([]);
  totalCustomers: WritableSignal<number> = signal(0)
  loading: WritableSignal<boolean> = signal(false);
  xlsxLoading: WritableSignal<boolean> = signal(false);
  memberXlsxLoading: WritableSignal<boolean> = signal(false);

  protected readonly statuses = statuses;

  constructor(
    public locationService: LocationService,
    public customerService: CustomerService,
    public weekdayService: WeekdayService,
    public router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    public authService: AuthService,
    public characteristicService: CharacteristicService,
  ) {
  }

  async loadCustomers(event?: TableLazyLoadEvent) {
    this.loading.set(true);

    this.lastTableLazyLoadEvent = event;
    const response = await this.customerService.fetchCustomers({
      filters: this.getStrapiFiltersForEvent(event ?? {}),
      pagination: event?.first != null && event.rows != null ? {
        page: Math.floor(event.first / event.rows) + 1,
        pageSize: event.rows
      } : {},
      sort: event?.sortField && event.sortOrder ? [event.sortField + (event.sortOrder < 0 ? ":desc" : ":asc")] : [],
      fields: ['first_name', 'last_name', 'status', 'street', 'house_number', 'address_addition', 'zip', 'city'],
      single_parent: this.singleParent,
      min_household: this.householdFilter ? this.minHousehold : null,
      max_household: this.householdFilter ? this.maxHousehold : null,
      populate: {
        code: { populate: ['location'] },
        code_rotation_1: { populate: ['location'] },
        code_rotation_2: { populate: ['location'] },
        future_code: { populate: ['location'] },
        future_code_rotation_1: { populate: ['location'] },
        future_code_rotation_2: { populate: ['location'] },
        family_members: true
      },
    });
    this.customers.set(response.data);
    this.totalCustomers.set(response.meta.pagination.total);
    this.loading.set(false);
  }

  async deleteCustomer(id: string, event: Event): Promise<void> {
    this.confirmationService.confirm({
      target: event.target ? event.target : undefined,
      message: 'Möchtest du diesen Nutzer wirklich löschen?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "JA",
      rejectLabel: "NEIN",
      accept: async () => {
        try {
          const customer = await this.customerService.deleteCustomer(id);
          await this.loadCustomers(this.lastTableLazyLoadEvent!);
          this.messageService.add({
            severity: 'success',
            summary: 'Nutzer gelöscht',
            detail: `${customer.first_name} ${customer.last_name} wurde gelöscht.`
          });
        } catch (e) {
          console.error(e);
          this.messageService.add({
            severity: 'error',
            summary: 'Fehler',
            detail: `Nutzer konnte nicht gelöscht werden.`
          });
        }
      }
    });

  }

  getStatusLabel(status: string): string {
    const stts = statuses.find(s => s.status == status);
    return stts?.title ?? "Unbekannt"
  }

  protected readonly RoleEnum = RoleEnum;

  async exportXLSX() {
    try {
      this.xlsxLoading.set(true);
      if (!this.lastTableLazyLoadEvent) {
        this.messageService.add({
          severity: 'error',
          summary: 'Fehler',
          detail: `Kein Event vorhanden`
        });
        return;
      }
      await this.customerService.exportXLSX({
        filters: this.getStrapiFiltersForEvent(this.lastTableLazyLoadEvent!),
        sort: this.lastTableLazyLoadEvent?.sortField && this.lastTableLazyLoadEvent.sortOrder ? [this.lastTableLazyLoadEvent.sortField + (this.lastTableLazyLoadEvent.sortOrder < 0 ? ":desc" : ":asc")] : [],
        single_parent: this.singleParent,
        min_household: this.householdFilter ? this.minHousehold : null,
        max_household: this.householdFilter ? this.maxHousehold : null,
      });
      this.messageService.add({
        severity: 'success',
        summary: 'Export',
        detail: `Daten wurden erfolgreich exportiert.`
      });
    } catch (err) {
      console.error(err);
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Daten konnten nicht exportiert werden.`
      });
    } finally {
      this.xlsxLoading.set(false);
    }
  }

  async exportMemberXLSX() {
    try {
      this.memberXlsxLoading.set(true);
      if (!this.lastTableLazyLoadEvent) {
        this.messageService.add({
          severity: 'error',
          summary: 'Fehler',
          detail: `Kein Event vorhanden`
        });
        return;
      }
      await this.customerService.exportMemberXLSX({
        filters: {
          birthDate: this.activateDate && this.bornAfter ? { $between: [format(this.bornAfter, 'yyyy-MM-dd'), format(this.bornBefore, 'yyyy-MM-dd')]} : {},
          customer: this.getStrapiFiltersForEvent(this.lastTableLazyLoadEvent!)
        },
        sort: this.lastTableLazyLoadEvent?.sortField && this.lastTableLazyLoadEvent.sortOrder ? [this.lastTableLazyLoadEvent.sortField + (this.lastTableLazyLoadEvent.sortOrder < 0 ? ":desc" : ":asc")] : [],
        single_parent: this.singleParent,
        min_household: this.householdFilter ? this.minHousehold : null,
        max_household: this.householdFilter ? this.maxHousehold : null,
      });
      this.messageService.add({
        severity: 'success',
        summary: 'Export',
        detail: `Daten wurden erfolgreich exportiert.`
      });
    } catch (err) {
      console.error(err);
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Daten konnten nicht exportiert werden.`
      });
    } finally {
      this.memberXlsxLoading.set(false);
    }
  }

  getStrapiFiltersForEvent(event: TableLazyLoadEvent) {
    const statuses: Status[] = (event.filters?.['status'] as FilterMetadata)?.value?.map((value: StatusModel<Status>) => value.status) ?? []
    const firstName = (event?.filters?.['first_name'] as FilterMetadata)?.value;
    const lastName = (event?.filters?.['last_name'] as FilterMetadata)?.value;
    const address = (event?.filters?.['address'] as FilterMetadata)?.value;
    const code = (event?.filters?.['code'] as FilterMetadata)?.value;
    return {
      $and: [
        {
          $or: this.selectedLocations.length > 0 ? [
            { code: { location: { id: { $in: this.selectedLocations } } } },
            { code_rotation_1: { location: { id: { $in: this.selectedLocations } } } },
            { code_rotation_2: { location: { id: { $in: this.selectedLocations } } } },
            { future_location: { id: { $in: this.selectedLocations } } }
          ] : []
        }, {
          $or: this.selectedWeekdays.length > 0 ? [
            { code: { weekday: { id: { $in: this.selectedWeekdays } } } },
            { code_rotation_2: { weekday: { id: { $in: this.selectedWeekdays } } } },
            { code_rotation_1: { weekday: { id: { $in: this.selectedWeekdays } } } },
          ] : []
        },
        this.selectedCharacteristics.length > 0 ? { characteristics: { id: { $in: this.selectedCharacteristics } } } : {},
        statuses.length > 0 ? { status: { $in: statuses } } : {},
        firstName ? { first_name: { $containsi: firstName } } : {},
        lastName ? { last_name: { $containsi: lastName } } : {},
        address ? {
          $or: [
            { street: { $containsi: address } },
            { house_number: { $containsi: address } },
            { zip: { $containsi: address } },
            { city: { $containsi: address } },
            { address_addition: { $containsi: address } },
          ]
        } : {},
        this.statusChange ? { $or: [{ status_change_date: { $notNull: true } }, { status_change_target: { $notNull: true } }] } : {},
        code ? {
          $or: [
            { code: { value: { $eq: code } } },
            { code_rotation_1: { value: { $eq: code } } },
            { code_rotation_2: { value: { $eq: code } } }
          ]
        } : {},
        this.activateDate && this.bornAfter ? {
          family_members: { birthDate: { $between: [format(this.bornAfter, 'yyyy-MM-dd'), format(this.bornBefore, 'yyyy-MM-dd')] } },
        } : {},
        this.activateOwnerDate && this.ownerBornAfter ? {
          family_members: {
            birthDate: { $between: [format(this.ownerBornAfter, 'yyyy-MM-dd'), format(this.ownerBornBefore, 'yyyy-MM-dd')] },
            isApplicant: true,
          },
        } : {},
        this.missingFutureLocation ? { future_location: { id: { $null: true } } } : {}
      ],
    }
  }
}
