import {Injectable} from '@angular/core';
import {ChartData} from "chart.js";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {firstValueFrom} from "rxjs";
import {statusColors, statuses} from "../customer-overview/add-edit-customer/status-form/status.model";
import {StatDateOption} from "./stats.component";
import {DailyStatistic, DayStat, IntervalStat, ThreeMonthStat} from "./daily-statistic.model";
import {format} from "date-fns";
import {FormStyle, getLocaleMonthNames, TranslationWidth} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class StatsService {
  statusData: ChartData = {
    datasets: [{ data: [] }],
    labels: []
  };

  weekdayData: ChartData = {
    datasets: [{ data: [] }],
    labels: []
  };
  locationData: ChartData = {
    datasets: [{ data: [] }],
    labels: []
  };
  absencesData: ChartData = {
    datasets: [{ data: [] }],
    labels: []
  }
  nationalitiesData: ChartData = {
    datasets: [{ data: [] }],
    labels: []
  }
  familyStatusData: ChartData = {
    datasets: [{ data: [] }],
    labels: []
  };
  incomeTypeData: ChartData = {
    datasets: [{ data: [] }],
    labels: []
  };
  characteristicsData: ChartData = {
    datasets: [{ data: [] }],
    labels: []
  }

  oneMonthStats: DailyStatistic[] = [];
  threeMonthsStats: ThreeMonthStat[] = [];
  oneYearStat: { [p: number]: DailyStatistic } = {};
  allYearStat: IntervalStat = {};

  historicIncomeData: ChartData = {
    datasets: [{ data: [] }],
    labels: []
  };
  historicStatusDistribution: ChartData = {
    datasets: [{ data: [] }],
    labels: []
  };
  historicPeopleProvided: ChartData = {
    datasets: [{ data: [] }],
    labels: []
  }
  historicAbsences: ChartData = {
    datasets: [{ data: [] }],
    labels: []
  }

  constructor(
    private http: HttpClient,
  ) {
    void this.fetchStats();
  }

  async fetchStats(): Promise<void> {
    this.statusData = await this.generateStatusPieData();
    this.weekdayData = await this.generateWeekdayData();
    this.locationData = await this.generateLocationData();
    this.absencesData = await this.generateAbsencesData();
    this.nationalitiesData = await this.generateNationalitiesData();
    this.familyStatusData = await this.generateFamilyStatusData();
    this.incomeTypeData = await this.generateIncomeTypeData();
    this.characteristicsData = await this.generateCharacteristicsData();
  }

  async generateStatusPieData(): Promise<ChartData> {
    const response = await firstValueFrom(this.http.get<{status: string, singleCount: number, familyCount: number}[]>(environment.apiUrl + `/customers/count-status/all`));

    return {
      datasets: [
        { label: 'Einzelpersonen', data: response.map(statusStat => statusStat.singleCount) },
        { label: 'Familien', data: response.map(statusStat => statusStat.familyCount) }
      ],
      labels: response.map(statusStat => statuses.find(s => s.status === statusStat.status)?.title),
    };
  }

  async fetchHistoricData(): Promise<void> {
    await Promise.all([
      this.fetchMonthStats(),
      this.fetchMonthsSummary(),
      this.fetchYearSummary(),
      this.fetchYearsSummary()
    ]);
  }

  async generateWeekdayData(): Promise<ChartData> {
    const response = await firstValueFrom(this.http.get<{title: string, count: number}[]>(environment.apiUrl + `/codes/count-weekdays/all`));

    return {
      datasets: [{ backgroundColor: '#368075', data: response.map(weekdayStat => weekdayStat.count) }],
      labels: response.map(weekdayStat => weekdayStat.title)
    };
  }

  async generateLocationData(): Promise<ChartData> {
    const response = await firstValueFrom(this.http.get<{title: string, householdCount: number, memberCount: number, under14Count: number}[]>(environment.apiUrl + `/codes/count-locations/all`));

    return {
      datasets: [
        { label: 'Haushalte Gesamt', data: response.map(locationStat => locationStat.householdCount) },
        { label: 'Personen', data: response.map(locationStat => locationStat.memberCount) },
        { label: 'Kinder unter 14 Jahre', data: response.map(locationStat => locationStat.under14Count) }
      ],
      labels: response.map(locationStat => locationStat.title)
    };
  }

  async generateAbsencesData(): Promise<ChartData> {
    const response = await firstValueFrom(this.http.get<{excusedCount: number, unexcusedCount: number, warnedCount: number}>(environment.apiUrl + `/absences/statistic/year`));

    return {
      datasets: [{ data: [response.excusedCount, response.unexcusedCount, response.warnedCount] }],
      labels: ["Entschuldigt", "Unentschuldigt", "Abmahnung"]
    };
  }

  async generateNationalitiesData() {
    const response = await firstValueFrom(this.http.get<Record<string, number>>(environment.apiUrl + `/nationalities/count/all`));
    const sortedResult = Object.entries(response).sort((a, b) => b[1] - a[1]);
    return {
      datasets: [{ data: sortedResult.map(i => i[1]) }],
      labels: sortedResult.map(i => i[0])
    };
  }

  async generateFamilyStatusData() {
    const response = await firstValueFrom(this.http.get<Record<string, number>>(environment.apiUrl + `/family-statuses/count/all`));
    const sortedResult = Object.entries(response).sort((a, b) => b[1] - a[1]);
    return {
      datasets: [{ data: sortedResult.map(i => i[1]) }],
      labels: sortedResult.map(i => i[0])
    };
  }

  async generateIncomeTypeData() {
    const response = await firstValueFrom(this.http.get<Record<string, number>>(environment.apiUrl + `/income-types/count/all`));
    const sortedResult = Object.entries(response).sort((a, b) => b[1] - a[1]);
    return {
      datasets: [{ backgroundColor: '#c8e6c9', data: sortedResult.map(i => i[1]) }],
      labels: sortedResult.map(i => i[0])
    };
  }

  async generateCharacteristicsData() {
    const response = await firstValueFrom(this.http.get<Record<string, number>>(environment.apiUrl + `/characteristics/count/all`));
    const sortedResult = Object.entries(response).sort((a, b) => b[1] - a[1]);
    return {
      datasets: [{ backgroundColor: '#d89b54', data: sortedResult.map(i => i[1]) }],
      labels: sortedResult.map(i => i[0])
    };
  }

  async fetchMonthStats(): Promise<void> {
    this.oneMonthStats = await firstValueFrom(this.http.get<DailyStatistic[]>(environment.apiUrl + `/daily-statistics/month/singles`));
  }

  async fetchMonthsSummary(): Promise<void> {
    this.threeMonthsStats = await firstValueFrom(this.http.get<ThreeMonthStat[]>(environment.apiUrl + `/daily-statistics/months/summary`));
  }

  async fetchYearSummary(): Promise<void> {
    this.oneYearStat = await firstValueFrom(this.http.get<IntervalStat>(environment.apiUrl + `/daily-statistics/year/summary`));
  }

  async fetchYearsSummary(): Promise<void> {
    this.allYearStat = await firstValueFrom(this.http.get<IntervalStat>(environment.apiUrl + `/daily-statistics/years/summary`));
  }

  async fetchToday(): Promise<DayStat> {
    return await firstValueFrom(this.http.get<DayStat>(environment.apiUrl + `/daily-statistics/today/today`));
  }

  configureStatInterval(interval: StatDateOption) {
    switch(interval) {
      case StatDateOption.ONE_MONTH:
        this.historicIncomeData = {
          labels: this.oneMonthStats.map(day => `${format(new Date(day.date), "dd.MM.")}`),
          datasets: [{
            data: this.oneMonthStats.map(day => day.income),
            label: "Einkommen",
            yAxisID: "y",
          }, {
            data: this.oneMonthStats.map(day => day.boxAmount),
            label: "Kisten",
            yAxisID: "y1",
          }]
        };
        this.historicStatusDistribution = {
          labels: this.oneMonthStats.map(day => `${format(new Date(day.date), "dd.MM.")}`),
          datasets: [{
            data: this.oneMonthStats.map(day => day.customersRequested),
            backgroundColor: statusColors.requested,
            label: "Anfrageliste",
          }, {
            data: this.oneMonthStats.map(day => day.customersQueued),
            backgroundColor: statusColors.queued,
            label: "Warteliste",
          }, {
            data: this.oneMonthStats.map(day => day.customersActive),
            backgroundColor: statusColors.active,
            label: "Aktiv",
          }, {
            data: this.oneMonthStats.map(day => day.customersBlocked),
            backgroundColor: statusColors.blocked,
            label: "Blockiert",
          }, {
            data: this.oneMonthStats.map(day => day.customersDroppedOut),
            backgroundColor: statusColors.dropped_out,
            label: "Ehemalige",
          }]
        };
        this.historicPeopleProvided = {
          labels: this.oneMonthStats.map(day => `${format(new Date(day.date), "dd.MM.")}`),
          datasets: [{
            data: this.oneMonthStats.map(day => day.totalAdultsProvided),
            label: "Erwachsene",
          }, {
            data: this.oneMonthStats.map(day => day.totalChildrenProvided),
            label: "Kinder",
          }]
        };
        this.historicAbsences = {
          labels: this.oneMonthStats.map(day => `${format(new Date(day.date), "dd.MM.")}`),
          datasets: [{
            data: this.oneMonthStats.map(day => day.absences),
            label: "Abwesenheiten",
          }]
        };
        break;
      case StatDateOption.THREE_MONTHS: {
        const threeMonthslabels = this.threeMonthsStats.map(week => `${format(new Date(week.rangeStart), "dd.MM.")} - ${format(new Date(week.rangeEnd), "dd.MM.")}`)
        this.historicIncomeData = {
          labels: threeMonthslabels,
          datasets: [{
            data: this.threeMonthsStats.map(week => week.income),
            label: "Einkommen",
            yAxisID: "y",
          }, {
            data: this.threeMonthsStats.map(week => week.boxAmount),
            label: "Kisten",
            yAxisID: "y1",
          }]
        };
        this.historicStatusDistribution = {
          labels: threeMonthslabels,
          datasets: [{
            data: this.threeMonthsStats.map(week => week.customersRequested),
            backgroundColor: statusColors.requested,
            label: "Anfrageliste",
          }, {
            data: this.threeMonthsStats.map(week => week.customersQueued),
            backgroundColor: statusColors.queued,
            label: "Warteliste",
          }, {
            data: this.threeMonthsStats.map(week => week.customersActive),
            backgroundColor: statusColors.active,
            label: "Aktiv",
          }, {
            data: this.threeMonthsStats.map(week => week.customersBlocked),
            backgroundColor: statusColors.blocked,
            label: "Blockiert",
          }, {
            data: this.threeMonthsStats.map(week => week.customersDroppedOut),
            backgroundColor: statusColors.dropped_out,
            label: "Ehemalige",
          }]
        }
        this.historicPeopleProvided = {
          labels: threeMonthslabels,
          datasets: [{
            data: this.threeMonthsStats.map(week => week.totalAdultsProvided),
            label: "Erwachsene",
          }, {
            data: this.threeMonthsStats.map(week => week.totalChildrenProvided),
            label: "Kinder",
          }]
        };
        this.historicAbsences = {
          labels: threeMonthslabels,
          datasets: [{
            data: this.threeMonthsStats.map(week => week.absences),
            label: "Abwesenheiten",
          }]
        };
        break;
      }
      case StatDateOption.ONE_YEAR: {
        const oneYearLabels = Object.entries(this.oneYearStat).map(([year, months]) => Object.keys(months).map((monthIndex) => `${getLocaleMonthNames("de", FormStyle.Format, TranslationWidth.Wide)[Number(monthIndex)]} ${year}`)).flat()
        const values = Object.values(this.oneYearStat).flatMap(months => Object.values(months)).flat();
        this.historicIncomeData = {
          labels: oneYearLabels,
          datasets: [{
            data: values.map(month => month.income),
            label: "Einkommen",
            yAxisID: "y",
          }, {
            data: values.map(month => month.boxAmount),
            label: "Kisten",
            yAxisID: "y1",
          }]
        };
        this.historicStatusDistribution = {
          labels: oneYearLabels,
          datasets: [{
            data: values.map(month => month.customersRequested),
            backgroundColor: statusColors.requested,
            label: "Anfrageliste",
          }, {
            data: values.map(month => month.customersQueued),
            backgroundColor: statusColors.queued,
            label: "Warteliste",
          }, {
            data: values.map(month => month.customersActive),
            backgroundColor: statusColors.active,
            label: "Aktiv",
          }, {
            data: values.map(month => month.customersBlocked),
            backgroundColor: statusColors.blocked,
            label: "Blockiert",
          }, {
            data: values.map(month => month.customersDroppedOut),
            backgroundColor: statusColors.dropped_out,
            label: "Ehemalige",
          }]
        };
        this.historicPeopleProvided = {
          labels: oneYearLabels,
          datasets: [{
            data: values.map(month => (month.totalAdultsProvided)),
            label: "Erwachsene",
          }, {
            data: values.map(month => (month.totalChildrenProvided)),
            label: "Kinder",
          }]
        };
        this.historicAbsences = {
          labels: oneYearLabels,
          datasets: [{
            data: values.map(month => month.absences),
            label: "Abwesenheiten",
          }]
        };
        break;
      }
      case StatDateOption.ALL_YEARS:
        this.historicIncomeData = {
          labels: Object.keys(this.allYearStat),
          datasets: [{
            data: Object.values(this.allYearStat).map(year => year.income),
            label: "Einkommen",
            yAxisID: "y",
          }, {
            data: Object.values(this.allYearStat).map(year => year.boxAmount),
            label: "Kisten",
            yAxisID: "y1",
          }]
        };
        this.historicStatusDistribution = {
          labels: Object.keys(this.allYearStat),
          datasets: [{
            data: Object.values(this.allYearStat).map(year => year.customersRequested),
            backgroundColor: statusColors.requested,
            label: "Anfrageliste",
          }, {
            data: Object.values(this.allYearStat).map(year => year.customersQueued),
            backgroundColor: statusColors.queued,
            label: "Warteliste",
          }, {
            data: Object.values(this.allYearStat).map(year => year.customersActive),
            backgroundColor: statusColors.active,
            label: "Aktiv",
          }, {
            data: Object.values(this.allYearStat).map(year => year.customersBlocked),
            backgroundColor: statusColors.blocked,
            label: "Blockiert",
          }, {
            data: Object.values(this.allYearStat).map(year => year.customersDroppedOut),
            backgroundColor: statusColors.dropped_out,
            label: "Ehemalige",
          }]
        };
        this.historicPeopleProvided = {
          labels: Object.keys(this.allYearStat),
          datasets: [{
            data: Object.values(this.allYearStat).map(year => year.totalAdultsProvided),
            label: "Erwachsene",
          }, {
            data: Object.values(this.allYearStat).map(year => year.totalChildrenProvided),
            label: "Kinder",
          }]
        };
        this.historicAbsences = {
          labels: Object.keys(this.allYearStat),
          datasets: [{
            data: Object.values(this.allYearStat).map(year => year.absences),
            label: "Abwesenheiten",
          }]
        };
        break;
    }
  }

  async getBadges(): Promise<{expiringCertificates: number; expiringExpiration: number, unseenMessages: number}> {
    return await firstValueFrom(this.http.get<{expiringCertificates: number; expiringExpiration: number, unseenMessages: number}>(environment.apiUrl + `/daily-statistics/sidebar/badges`));
  }
}
