<p-confirmPopup></p-confirmPopup>
<p-card class="w-100" header="Filter">
  <div class="row">
    <div class="col-12 md:col-3">
      <h5>Ausgabeort</h5>
      @for (loc of locationService.locations; track loc.id) {
      <div class="field-checkbox">
        <p-checkbox [value]="loc.id" [(ngModel)]="selectedLocations"
          [inputId]="'loc-' + loc.id.toString()"></p-checkbox>
        <label [for]="'loc-' + loc.id">{{loc.city}}</label>
      </div>
      }
    </div>
    <div class="col-12 md:col-3">
      <h5>Wochentage</h5>
      @for (weekday of weekdayService.weekdays; track weekday.id) {
      <div class="field-checkbox">
        <p-checkbox [value]="weekday.id" [(ngModel)]="selectedWeekdays" [inputId]="'day-' + weekday.id"></p-checkbox>
        <label [for]="'day-' + weekday.id">{{weekday.title}}</label>
      </div>
      }

      <h5>Eigenschaften</h5>
      @for (characteristic of characteristicService.characteristics; track characteristic.id) {
      <div class="field-checkbox">
        <p-checkbox [value]="characteristic.id" [(ngModel)]="selectedCharacteristics"
          [inputId]="'characteristic-' + characteristic.id"></p-checkbox>
        <label [for]="'characteristic-' + characteristic.id">{{characteristic.title}}</label>
      </div>
      }
    </div>
    <div class="col-12 md:col-6">
      <h5>Allgemein</h5>
      <div class="d-flex">
        <p-checkbox [binary]="true" [(ngModel)]="activateDate"></p-checkbox>
        <span class="ml-2">Familienmitglied</span>
      </div>
      <div class="flex">
        <span class="p-float-label mt-4 ml-2">
          <p-calendar inputId="born_after" styleClass="w-100" dateFormat="dd.mm.yy" [disabled]="!activateDate"
            [(ngModel)]="bornAfter" appendTo="body"></p-calendar>
          <label [for]="'born_after'">Geboren von</label>
        </span>
        <span class="p-float-label mt-4 ml-2">
          <p-calendar inputId="born_before" styleClass="w-100" dateFormat="dd.mm.yy" [disabled]="!activateDate"
            [(ngModel)]="bornBefore" appendTo="body"></p-calendar>
          <label [for]="'born_before'">Geboren bis</label>
        </span>
      </div>
      <div class="d-flex mt-2">
        <p-checkbox [binary]="true" [(ngModel)]="activateOwnerDate"></p-checkbox>
        <span class="ml-2">Antragsteller</span>
      </div>
      <div class="flex">
        <span class="p-float-label mt-4 ml-2">
          <p-calendar inputId="owner_born_after" styleClass="w-100" dateFormat="dd.mm.yy"
            [disabled]="!activateOwnerDate" [(ngModel)]="ownerBornAfter" appendTo="body"></p-calendar>
          <label [for]="'owner_born_after'">Geboren von</label>
        </span>
        <span class="p-float-label mt-4 ml-2">
          <p-calendar inputId="owner_born_before" styleClass="w-100" dateFormat="dd.mm.yy"
            [disabled]="!activateOwnerDate" [(ngModel)]="ownerBornBefore" appendTo="body"></p-calendar>
          <label [for]="'owner_born_before'">Geboren bis</label>
        </span>
      </div>
      <div class="field-checkbox mt-3">
        <p-checkbox [binary]="true" [(ngModel)]="singleParent" inputId="singleParent"></p-checkbox>
        <label [for]="'singleParent'">Alleinerziehend</label>
      </div>
      <div class="field-checkbox mt-3">
        <p-checkbox [binary]="true" [(ngModel)]="statusChange" inputId="status_change"></p-checkbox>
        <label [for]="'status_change'">Geplante Statusänderung</label>
      </div>
      <div class="field-checkbox mt-3">
        <p-checkbox [binary]="true" [(ngModel)]="missingFutureLocation" inputId="missing_future_location"></p-checkbox>
        <label [for]="'missing_future_location'">Kein künftiger Ausgabeort</label>
      </div>
      <div class="d-flex align-items-baseline flex-wrap">
        <p-checkbox [binary]="true" [(ngModel)]="householdFilter"></p-checkbox>
        <span class="p-float-label mt-2 ml-2">
          <p-dropdown inputId="minHousehold" [options]="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]"
            [(ngModel)]="minHousehold" [disabled]="!householdFilter" [showClear]="true" />
          <label [for]="'minHousehold'">Haushaltsgröße Min.</label>
        </span>
        <span class="p-float-label mt-2 ml-2">
          <p-dropdown inputId="maxHousehold" [options]="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]"
            [(ngModel)]="maxHousehold" [disabled]="!householdFilter" [showClear]="true" />
          <label [for]="'maxHousehold'">Haushaltsgröße Max.</label>
        </span>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-end align-items-center">
    <button [disabled]="memberXlsxLoading()" class="p-link text-primary mr-3" (click)="exportMemberXLSX()">
      @if (memberXlsxLoading()) {
        <i class="pi pi-spinner"></i>
      } @else {
        <i class="pi pi-download"></i>
      }
      Familienmitglieder-Export
    </button>
    <button [disabled]="xlsxLoading()" class="p-link text-primary mr-3" (click)="exportXLSX()">
      @if (xlsxLoading()) {
      <i class="pi pi-spinner"></i>
      } @else {
      <i class="pi pi-download"></i>
      }
      Nutzer-Export
    </button>
    <button pButton pRipple icon="pi pi-filter" label="FILTERN"
      (click)="loadCustomers(lastTableLazyLoadEvent)"></button>
  </div>
</p-card>
<div class="mt-3">
  <p-card class="w-100">
    <p-table [value]="customers()" [lazy]="true" (onLazyLoad)="loadCustomers($event)" [paginator]="true" [rows]="25"
      [totalRecords]="totalCustomers()" [showCurrentPageReport]="true" [loading]="loading()" dataKey="id"
      [rowsPerPageOptions]="[10, 25, 50, 100]"
      currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Nutzern">
      <ng-template pTemplate="header">
        <tr>
          <th>Nr.</th>
          <th pSortableColumn="first_name">Vorname
            <p-sortIcon field="first_name"></p-sortIcon>
          </th>
          <th pSortableColumn="last_name">Nachname
            <p-sortIcon field="last_name"></p-sortIcon>
          </th>
          <th pSortableColumn="street">Adresse
            <p-sortIcon field="street"></p-sortIcon>
          </th>
          <th pSortableColumn="status">Status
            <p-sortIcon field="status"></p-sortIcon>
          </th>
          <th>Haushalt</th>
          <th></th>
        </tr>

        <tr>
          <th>
            <p-columnFilter type="text" field="code" [showMenu]="false"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter type="text" field="first_name" [showMenu]="false"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter type="text" field="last_name" [showMenu]="false"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter type="text" field="address" [showMenu]="false"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter field="status" matchMode="in" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect appendTo="body" [ngModel]="value" [options]="statuses" (onChange)="filter($event.value)"
                  [filter]="false" optionLabel="title" placeholder="Alle">
                  <ng-template let-option pTemplate="item">
                    <span class='status-badge status-{{option.status}}'>{{option.title}}</span>
                  </ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
          <th></th>
          <th style="min-width: 140px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-customer>
        <tr>
          <td>
            @if (customer.code) {
            <span>{{customer.code.location.abbreviation}} {{customer.code.value}}</span>
            }
            @if (customer.code_rotation_1) {
            <span>{{customer.code_rotation_1.location.abbreviation}}
              {{customer.code_rotation_1.value}}-1</span>
            }
            @if (customer.code_rotation_2) {
            <span>{{customer.code_rotation_2.location.abbreviation}}
              {{customer.code_rotation_2.value}}-2</span>
            }
          </td>
          <td>{{customer.first_name}}</td>
          <td>{{customer.last_name}}</td>
          <td>
            <span>{{customer.street}} {{customer.house_number}}</span><br>
            @if (customer.address_addition) {
            <span>{{customer.address_addition}}</span><br>
            }
            {{customer.zip}} {{customer.city}}
          </td>
          <td>@if (customer.status) {
            <span class="status-badge status-{{customer.status}}">{{getStatusLabel(customer.status)}}</span>
            }
          </td>
          <td>{{customer.family_members | household | async}}</td>
          <td>
            <a [routerLink]="['edit', customer.id]" pButton pRipple type="button" icon="pi pi-pencil"
              class="p-button-rounded p-button-outlined"></a>
            @if (authService.user()?.role?.id === RoleEnum.Admin) {
            <button pButton pRipple type="button" icon="pi pi-trash"
              class="ms-2 p-button-rounded p-button-danger p-button-outlined"
              (click)="deleteCustomer(customer.id, $event)"></button>
            }
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="6">Keine Nutzer gefunden</td>
        </tr>
      </ng-template>
    </p-table>
  </p-card>
</div>
