import {inject, Injectable} from '@angular/core';
import {LoadingState} from "../shared/loading-state.enum";
import {HttpClient} from "@angular/common/http";
import {firstValueFrom} from "rxjs";
import {environment} from "../../environments/environment";
import {AddressCompletion, Customer} from "./customer.model";
import {ResponseModel, SingleResponseModel} from "../shared/response.model";
import * as qs from "qs";
import {statuses} from "./add-edit-customer/status-form/status.model";
import {format} from "date-fns";
import * as FileSaver from "file-saver";

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  loadingState = LoadingState.Loading;
  customers: Customer[] = [];
  http = inject(HttpClient);

  async fetchCustomers(params?: unknown): Promise<ResponseModel<Customer>> {
    this.loadingState = LoadingState.Loading;
    const response = await firstValueFrom(this.http.get<ResponseModel<Customer>>(environment.apiUrl + `/customers?${qs.stringify(params, {encodeValuesOnly: true})}`));
    this.loadingState = LoadingState.Data;
    return response;
  }

  async getCustomer(id: string): Promise<Customer | void> {
    try {
      const query = qs.stringify({
        populate: {
          absences: {
            populate: 'absence_status'
          },
          attachments: true,
          code_rotation_1: {
            populate: ['location', 'weekday']
          },
          code_rotation_2: {
            populate: ['location', 'weekday']
          },
          code: {
            populate: ['location', 'weekday']
          },
          future_code_rotation_1: {
            populate: ['location', 'weekday']
          },
          future_code_rotation_2: {
            populate: ['location', 'weekday']
          },
          future_code: {
            populate: ['location', 'weekday']
          },
          family_status: true,
          nationality: true,
          income_type_a: true,
          income_type_b: true,
          income_type_c: true,
          characteristics: true,
          future_location: true,
          supervisors: true,
          comments: {
            populate: ['contact_type', 'users_permissions_user']
          },
          family_members: {
            populate: ['gender']
          },
          status_change_reason: true,
          customer_events: {
            populate: 'users_permissions_user'
          },
          pickups: {
            populate: true,
            limit: 1,
            sort: 'date:desc'
          }
        },
      }, {encodeValuesOnly: true});
      const response = await firstValueFrom(this.http.get<SingleResponseModel<Customer>>(environment.apiUrl + `/customers/${id}?${query}`));
      return response.data;
    } catch (e) {
      console.error(e);
    }
  }

  async createCustomer(customer: Partial<Customer>): Promise<Customer | void> {
    const response = await firstValueFrom(this.http.post<SingleResponseModel<Customer>>(environment.apiUrl + "/customers", {data: customer}));
    return response?.data;
  }

  async updateCustomer(customer: Partial<Customer>): Promise<Customer | void> {
    const response = await firstValueFrom(this.http.put<SingleResponseModel<Customer>>(environment.apiUrl + `/customers/${customer.id}`, {data: customer}));
    return response?.data;
  }

  async deleteCustomer(id: string): Promise<Customer> {
    return (await firstValueFrom(this.http.delete<SingleResponseModel<Customer>>(environment.apiUrl + `/customers/${id}`))).data;
  }

  async getAddressSuggestions(street: string): Promise<AddressCompletion[]> {
    const stringifiedQuery = qs.stringify({
      filters: {
        street: {$containsi: street},
      },
      fields: ['street', 'zip', 'city'],
      sort: 'street:asc',
    })

    try {
      const response = await firstValueFrom(this.http.get<ResponseModel<Customer>>(environment.apiUrl + `/customers?${stringifiedQuery}`));
      const customers = response.data;

      const addresses: AddressCompletion[] = customers.map((customer) => {
        /* Remove house number if present by keeping everything until the first digit */
        const tokens = customer.street.split(' ');
        let sanitizedStreetName = '';
        for (const token of tokens) {
          if (token.match(/\d/)) {
            break;
          }
          sanitizedStreetName += token + ' ';
        }

        return {
          street: sanitizedStreetName.trim(),
          zip: customer.zip.trim(),
          city: customer.city.trim(),
        }
      });

      /* Remove duplicates */
      const uniqueAddresses: AddressCompletion[] = [];
      for (const address of addresses) {
        if (!uniqueAddresses.find((uniqueAddress) => uniqueAddress.street === address.street && uniqueAddress.zip === address.zip && uniqueAddress.city === address.city)) {
          uniqueAddresses.push(address);
        }
      }
      return uniqueAddresses;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async exportXLSX(params?: unknown) {
    const response = await firstValueFrom(this.http.get(environment.apiUrl + `/customers/export/xlsx?${qs.stringify(params, {encodeValuesOnly: true})}`, {responseType: 'blob'}))
    const blob = new Blob([response]);
    FileSaver.saveAs(blob, `nutzer-${format(new Date(), 'yy-MM-dd')}.xlsx`);
  }

  async exportMemberXLSX(params?: unknown) {
    const response = await firstValueFrom(this.http.get(environment.apiUrl + `/family-members/export/xlsx?${qs.stringify(params, {encodeValuesOnly: true})}`, {responseType: 'blob'}))
    const blob = new Blob([response]);
    FileSaver.saveAs(blob, `mitglieder-${format(new Date(), 'yy-MM-dd')}.xlsx`);
  }

  getHumanFriendlyStatus(status: string) {
    for (const statusModel of statuses) {
      if (statusModel.status === status) {
        return statusModel.title;
      }
    }

    return "Unbekannt";
  }
}
