<div class="container">
  <app-simple-messages></app-simple-messages>

  <p-listbox
    scrollHeight="auto"
    [options]="locationService.locations"
    [readonly]="true"
    styleClass="mt-3"
  >
    <ng-template let-location pTemplate="item">
      <div class="flex align-items-center p-1 w-100" (click)="chooseLocation(location.id)">
        <div class="list-item-details flex-grow-1">
          <div class="flex justify-content-center align-items-center m-3">
            <div class="text-2xl">
              {{ location.city }}
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </p-listbox>
</div>
