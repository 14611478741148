import { Component } from '@angular/core';
import {LocationService} from "../administration/location/location.service";
import {AsyncPipe, SlicePipe} from "@angular/common";
import {CodePipe} from "../shared/pipes/code.pipe";
import {HouseholdPipe} from "../shared/pipes/household.pipe";
import {ListboxModule} from "primeng/listbox";
import {SharedModule} from "primeng/api";
import {Router} from "@angular/router";
import {SimpleMessagesComponent} from "../shared/simple-messages/simple-messages.component";

@Component({
  selector: 'app-pickup-locations',
  standalone: true,
  imports: [
    AsyncPipe,
    CodePipe,
    HouseholdPipe,
    ListboxModule,
    SharedModule,
    SlicePipe,
    SimpleMessagesComponent
  ],
  templateUrl: './pickup-locations.component.html',
  styleUrl: './pickup-locations.component.scss'
})
export class PickupLocationsComponent {

  constructor(
    public locationService: LocationService,
    private router: Router
  ) {}

  chooseLocation(locationId: number) {
    void this.router.navigate(['pickup', locationId]);
  }
}
