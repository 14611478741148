<div class="field col-12">
      <span class="p-float-label">
        <p-calendar inputId="date" styleClass="w-100" dateFormat="dd.mm.yy" [(ngModel)]="date" (ngModelChange)="loadPickups(tableLoadEvent())"></p-calendar>
        <label [for]="'date'">Datum</label>
      </span>
</div>

@if (stats()) {
  <div class="grid mt-3">
    <div class="col-3">
      <div class="w-100 d-flex justify-content-center flex-column shadow-4 bg-gray-50 rounded py-3">
        <span class="text-center">Nutzer</span>
        <div class="d-flex justify-content-center">
          <span class="text-center text-4xl ml-2 mr-2">{{ stats()!.count }}</span>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="w-100 d-flex justify-content-center flex-column shadow-4 bg-gray-50 rounded py-3">
        <span class="text-center">Einnahmen</span>
        <div class="d-flex justify-content-center">
          <span class="text-center text-4xl ml-2 mr-2">{{ stats()!.income }} €</span>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="w-100 d-flex justify-content-center flex-column shadow-4 bg-gray-50 rounded py-3">
        <span class="text-center">Schulden</span>
        <div class="d-flex justify-content-center">
          <span class="text-center text-4xl ml-2 mr-2">{{ stats()!.newDebt }} €</span>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="w-100 d-flex justify-content-center flex-column shadow-4 bg-gray-50 rounded py-3">
        <span class="text-center">Kisten</span>
        <div class="d-flex justify-content-center">
          <span class="text-center text-4xl ml-2 mr-2">{{ stats()!.boxes }}</span>
        </div>
      </div>
    </div>
  </div>
}


<p-table [value]="pickups()"
         [lazy]="true"
         (onLazyLoad)="loadPickups($event)"
         [paginator]="true"
         [rows]="100"
         [showCurrentPageReport]="true"
         [totalRecords]="totalPickups()"
         [loading]="pickupsLoading()"
         [rowsPerPageOptions]="[10, 25, 50, 100]"
         dataKey="id"
         currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Nutzern"
>

  <ng-template pTemplate="header">
    <tr>
      <th>Nr.</th>
      <th>Name</th>
      <th>Bezahlter Betrag</th>
      <th>Offener Schuldbetrag</th>
      <th>Kommentar</th>
      <th style="min-width: 70px;"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-pickup>
    <tr>
      <td>
        @if (pickup.customer.code) {
          <span>{{pickup.customer.code.location.abbreviation}} {{pickup.customer.code.value}}</span>
        }
        @if (pickup.customer.code_rotation_1) {
          <span>{{pickup.customer.code_rotation_1.location.abbreviation}} {{pickup.customer.code_rotation_1.value}}-1</span>
        }
        @if (pickup.customer.code_rotation_2) {
          <span>{{pickup.customer.code_rotation_2.location.abbreviation}} {{pickup.customer.code_rotation_2.value}}-2</span>
        }
      </td>
      <td>{{pickup.customer.last_name}}, {{pickup.customer.first_name}}</td>
      <td>{{pickup.paid}} €</td>
      <td>{{pickup.unpaid_balance}} €</td>
      <td>{{pickup.comment?.comment}}</td>
      <td><button pButton class="p-button-rounded p-button-outlined" icon="pi pi-external-link" (click)="router.navigate(['customers', 'edit', pickup.customer.id])"></button> </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="6">Keine Abholungen gefunden</td>
    </tr>
  </ng-template>
</p-table>
