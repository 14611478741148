<div class="p-2">
  <app-simple-messages></app-simple-messages>
  <p-card header="{{today | date : 'EEEE, dd.MM.yyyy'}}">
    @if (loading || !settingsService.settings()) {
      <div class="w-100 flex justify-content-center">
        <p-progressSpinner ariaLabel="loading"></p-progressSpinner>
      </div>
    }

    <div class="grid mt-3">
      <div class="col-4">
        <div class="w-100 d-flex justify-content-center flex-column shadow-4 bg-gray-50 rounded py-3">
          <span class="text-center">Kisten verbleibend</span>
          <div class="d-flex justify-content-center">
            <span class="text-center text-4xl ml-2 mr-2">{{ getRemainingBoxCount() }}</span>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="w-100 d-flex justify-content-center flex-column shadow-4 bg-gray-50 rounded py-3">
          <span class="text-center">Nutzer verbleibend</span>
          <div class="d-flex justify-content-center">
            <span class="text-center text-4xl ml-2 mr-2">{{ getRemainingCustomersCount() }}</span>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="w-100 d-flex justify-content-center flex-column shadow-4 bg-gray-50 rounded py-3">
          <span class="text-center">Bisheriges Einkommen</span>
          <div class="d-flex justify-content-center">
            <span class="text-center text-4xl ml-2 mr-2">{{ getCurrentIncome() }} €</span>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-5">
      <p-progressBar [value]="completionPercentage()"/>
    </div>
    @if (customersAtCooling().length > 0) {
      <h3>Kühlung</h3>

      <p-listbox
        scrollHeight="auto"
        [options]="customersAtCooling()"
        [style]="{ width: '100%' }"
        styleClass="mb-3"
        [readonly]="true"
      >
        <ng-template let-customer pTemplate="item">
          <div class="flex align-items-center p-1 w-100">
            <div class="list-item-details flex-grow-1">
              <div class="flex justify-content-between">
                <h5 class="mb-3 mr-1">
                  {{ customer.first_name | slice : 0 : 1 }}. {{ customer.last_name }}
                  <span>({{ customer | code }})</span>
                </h5>
              </div>

              <div>
                @if (location()?.showCustomerAddress) {
                  <p-tag styleClass="mr-1"
                         value="{{customer.street}} {{customer.house_number}}, {{customer.zip}}  {{customer.city}}"></p-tag>
                }
                <p-tag styleClass="mr-1" value="{{settingsService.calculateBoxCount(customer)}} Kiste(n)"></p-tag>
                @if ((customer.code ?? customer.code_rotation_1 ?? customer.code_rotation_2).isFreeCode) {
                  <p-tag styleClass="mr-1" value="GRATIS"></p-tag>
                } @else {
                  <p-tag styleClass="mr-1"
                         value="{{settingsService.calculatePrice(customer) + customer.pickups?.[0]?.unpaid_balance ?? 0}} €"></p-tag>
                }
                <p-tag styleClass="mr-1" value="{{customer.family_members | household | async}}"></p-tag>
                @if (this.customerHasChildren(customer.family_members)) {
                  <p-tag styleClass="mr-1">
                    Alter:
                    {{ customer.family_members | agesList }}
                  </p-tag>
                }
                @if (customer.shop_remark) {
                  <p-tag styleClass="mr-1" [value]="customer.shop_remark"></p-tag>
                }
                @if (customer.collection_remark) {
                  <p-tag styleClass="mr-1" [value]="customer.collection_remark"></p-tag>
                }
                @if (!customer.pickups) {
                  <p-tag styleClass="mr-1" value="NEU"></p-tag>
                }
              </div>

              <button pButton class="p-button-outlined mt-2 p-button-sm" (click)="receivedCooling(customer.id)">KÜHLUNG ERHALTEN</button>
            </div>
          </div>
        </ng-template>
      </p-listbox>
    }

    @if (customersToday().length > 0) {
      <div class="mb-3 w-100">
        <p-floatLabel>
          <input class="w-100" id="search" type="text" (keyup)="filterCustomers($any($event.target).value)" pInputText/>
          <label for="search">Suche</label>
        </p-floatLabel>
      </div>

      @for (group of customersToday(); track group.time) {
        @if (group.time) {
          <h3>{{ group.time | slice:0:5 }} Uhr</h3>
        }

        <p-listbox
          scrollHeight="auto"
          [options]="group.customers"
          [style]="{ width: '100%' }"
          styleClass="mb-3"
          [readonly]="true"
        >
          <ng-template let-customer pTemplate="item">
            <div class="flex align-items-center p-1 w-100" (click)="authService.user()?.role?.id !== RoleEnum.READ ? handleCustomer(customer) : undefined">
              <div class="list-item-details flex-grow-1">
                <div class="flex justify-content-between">
                  <h5 class="mb-3 mr-1">
                    {{ customer.first_name | slice : 0 : 1 }}. {{ customer.last_name }}
                    <span>({{ customer | code }})</span>
                  </h5>
                </div>

                <div>
                  @if (location()?.showCustomerAddress) {
                    <p-tag styleClass="mr-1"
                           value="{{customer.street}} {{customer.house_number}}, {{customer.zip}}  {{customer.city}}"></p-tag>
                  }
                  <p-tag styleClass="mr-1" value="{{settingsService.calculateBoxCount(customer)}} Kiste(n)"></p-tag>
                  @if ((customer.code ?? customer.code_rotation_1 ?? customer.code_rotation_2).isFreeCode) {
                    <p-tag styleClass="mr-1" value="GRATIS"></p-tag>
                  } @else {
                    <p-tag styleClass="mr-1"
                           value="{{settingsService.calculatePrice(customer) + customer.pickups?.[0]?.unpaid_balance ?? 0}} €"></p-tag>
                  }
                  <p-tag styleClass="mr-1" value="{{customer.family_members | household | async}}"></p-tag>
                  @if (this.customerHasChildren(customer.family_members)) {
                    <p-tag styleClass="mr-1">
                      Alter:
                      {{ customer.family_members | agesList }}
                    </p-tag>
                  }
                  @if (customer.shop_remark) {
                    <p-tag styleClass="mr-1" [value]="customer.shop_remark"></p-tag>
                  }
                  @if (customer.collection_remark) {
                    <p-tag styleClass="mr-1" [value]="customer.collection_remark"></p-tag>
                  }
                  @if (!customer.pickups) {
                    <p-tag styleClass="mr-1" value="NEU"></p-tag>
                  }
                </div>

              </div>
            </div>
          </ng-template>
        </p-listbox>
      }
    } @else {
      <span class="text-black-alpha-70">
        Keine verbleibenden Nutzer
      </span>
    }
    <p-panel header="Abgeschlossen" styleClass="mt-2" [toggleable]="true" [collapsed]="true">
      @if (completedCustomers().length > 0) {
        <p-listbox
          scrollHeight="auto"
          [options]="completedCustomers()"
          [style]="{ width: '100%' }"
          styleClass="mb-3"
          [readonly]="true"
        >
          <ng-template let-customer pTemplate="item">
            <div class="flex align-items-center p-1 w-100"
                 (click)="authService.user()?.role?.id !== RoleEnum.READ ? handleCustomer(customer) : undefined">
              <div class="list-item-details flex-grow-1">
                <div class="flex justify-content-between">
                  <h5 class="mb-3 mr-1">
                    {{ customer.first_name | slice : 0 : 1 }}. {{ customer.last_name }}
                    <span>({{ customer | code }})</span>
                  </h5>
                </div>

                <div class="flex justify-content-between">
                  <div>
                    <span>
                      {{ customer.code?.time ?? customer.code_rotation_1?.time ?? customer.code_rotation_2?.time | slice : 0:5 }}Uhr
                    </span>
                    @if (this.getTodayPickUp(customer)?.comment) {
                      <span> - {{this.getTodayPickUp(customer)?.comment?.comment}}</span>
                    }
                  </div>
                  <span>
                    @if (this.getTodayAbsence(customer)) {
                      <p-tag severity="danger" styleClass="mr-1"
                             value="ABWESEND ({{this.getAbsenceStatusLabel(this.getTodayAbsence(customer)!.absence_status)}})"></p-tag>
                    }
                    @if (this.getTodayPickUp(customer)) {
                      <p-tag severity="success" styleClass="mr-1" value="ABGEHOLT"></p-tag>
                    }
                    @if (this.getTodayPickUp(customer) && customer.unpaid_balance) {
                      <p-tag styleClass="mr-1" value="{{customer.unpaid_balance}} €"></p-tag>
                    }
                  </span>
                </div>

              </div>
            </div>
          </ng-template>
        </p-listbox>
      } @else {
        <span class="text-black-alpha-70">Keine fertigen Nutzer</span>
      }
    </p-panel>
    <div class="flex justify-content-center mt-5">
      <p-button [link]="true" (click)="authService.logout()">Logout</p-button>
    </div>
  </p-card>
</div>

<p-sidebar [visible]="selectedCustomer() !== undefined"
           (visibleChange)="selectedCustomer.set($event ? selectedCustomer() : undefined)"
           position="bottom">
  @if (selectedCustomer()) {
    <h3>{{ selectedCustomer()!.first_name }} {{ selectedCustomer()!.last_name }} ({{ selectedCustomer()! | code }})</h3>
    @if (this.authService.user()?.role?.id === RoleEnum.Admin || this.authService.user()?.role?.id === RoleEnum.Member) {
      <a routerLink="/customers/edit/{{selectedCustomer()?.id}}">Im Dashboard öffnen</a>
    }
    @if (selectedCustomer()!.shop_remark) {
      <div class="text-xl">
        <span class="font-bold">Bemerkung Ladendienst: </span>
        <span class="ml-1"> {{ selectedCustomer()!.shop_remark }}
          (bis {{ selectedCustomer()!.shop_remark_valid_until | date : 'dd.MM.yyyy' }})</span>
      </div>
    }
    @if (selectedCustomer()!.collection_remark) {
      <div class="text-xl">
        <span class="font-bold">Besonderheit Abholung:</span>
        <span class="ml-1">{{ selectedCustomer()!.collection_remark }}</span>
      </div>
    }

    @if (settingsService.settings()) {
      <div class="grid mt-3">
        <div class="col-6 md:col-3">
          <div class="w-100 d-flex justify-content-center flex-column shadow-4 bg-gray-50 rounded py-3">
            <span class="text-center">Erwachsene (> {{ settingsService.settings()!.adultAge }} Jahre)</span>
            <div class="d-flex justify-content-center">
              <span class="text-center text-4xl ml-2 mr-2">{{ selectedAdultCount() }}</span>
            </div>
          </div>
        </div>
        <div class="col-6 md:col-3">
          <div class="w-100 d-flex justify-content-center flex-column shadow-4 bg-gray-50 rounded py-3">
            <span class="text-center">Kinder (< {{ settingsService.settings()!.adultAge }} Jahre)</span>
            <div class="d-flex justify-content-center">
              <span class="text-center text-4xl ml-2 mr-2">{{ childrenCount() }}</span>
            </div>
          </div>
        </div>
        <div class="col-6 md:col-3">
          <div class="w-100 d-flex justify-content-center flex-column shadow-4 bg-gray-50 rounded py-3">
            <span class="text-center">Kisten</span>
            <span class="text-center text-4xl">{{ boxCount() }}</span>
          </div>
        </div>
        <div class="col-6 md:col-3">
          <div class="w-100 d-flex justify-content-center flex-column shadow-4 bg-gray-50 rounded py-3">
            <span class="text-center">
              Betrag
              @if (selectedCustomer()?.pickups?.[0]?.unpaid_balance) {
                <span>(+ Nachzahlung {{ selectedCustomer()?.pickups?.[0]?.date | date : 'dd.MM.yyyy' }})</span>
              }
            </span>
            <span class="text-center text-4xl">
              {{ calculatedPrice() }} €
              @if (selectedCustomer()!.pickups?.[0]?.unpaid_balance) {
                <span> + {{ selectedCustomer()!.pickups![0].unpaid_balance }}
                  € = {{ calculatedPrice() + selectedCustomer()!.pickups![0].unpaid_balance }} €</span>
              }
            </span>
          </div>
        </div>
      </div>
    }

    <div class="flex flex-column gap-2">
      <label for="new_unpaid_balance">Restschuld (nur wenn abgeholt)</label>
      <input pInputText type="number" class="w-100" id="new_unpaid_balance" aria-describedby="new_unpaid_balance_help"
             [(ngModel)]="newUnpaidBalance"/>
      <small id="new_unpaid_balance_help">
        Die jetzt offene Schuldposition (Gesamtbetrag - Gezahltes)
      </small>
    </div>

    <div class="mt-3">
      <label for="comment">Kommentar</label>
      <textarea pInputTextarea type="number" class="w-100" id="comment"
                [(ngModel)]="comment"></textarea>
    </div>

    <div class="grid mt-3">
      <div class="col-6">
        <button pButton class="p-button-danger w-100 h-100 justify-content-center" (click)="markAbsent()"
                [disabled]="getTodayAbsence(selectedCustomer()!)">NICHT ERSCHIENEN
        </button>
      </div>
      <div class="col-6">
        <button pButton class="p-button-success w-100 h-100 justify-content-center" (click)="pickUpBox()"
                [disabled]="getTodayPickUp(selectedCustomer()!)">
          {{ calculatedPrice() + (selectedCustomer()!.pickups?.[0]?.unpaid_balance ?? 0) - newUnpaidBalance }} €
          BEZAHLT (WEITER ZUR KÜHLUNG)
        </button>
      </div>
    </div>
  }
</p-sidebar>
