import {Component, inject, OnInit, signal, WritableSignal} from '@angular/core';
import {AlertMessage} from "../../alert-messages/alert-message.model";
import {AlertMessagesService} from "../../alert-messages/alert-messages.service";
import {AuthService} from "../../login/auth.service";
import {MessagesModule} from "primeng/messages";
import {Message} from "primeng/api";

@Component({
  selector: 'app-simple-messages',
  standalone: true,
  imports: [
    MessagesModule
  ],
  templateUrl: './simple-messages.component.html',
  styleUrl: './simple-messages.component.scss'
})
export class SimpleMessagesComponent implements OnInit {
  messages: WritableSignal<Message[]> = signal([]);
  loadingMessages: WritableSignal<boolean> = signal(false);

  messagesService: AlertMessagesService = inject(AlertMessagesService);
  authService: AuthService = inject(AuthService);

  ngOnInit() {
    void this.loadMessages()
  }

  async loadMessages() {
    this.loadingMessages.set(true);
    if (this.authService.user() === null) {
      await this.authService.fetchMe()
    }
    const response = await this.messagesService.fetchMyReceivedMessages({
      filters: {
        recipient: {
          id: {
            $eq: this.authService.user()!.id
          }
        },
        seen: { $eq: false }
      },
      populate: ["author"],
      sort: ['seen:asc', 'createdAt:desc'],
    });
    this.messages.set(response?.data?.map((alertMessage: AlertMessage) => ({
      severity: 'info',
      detail: `<strong>${alertMessage.author.first_name} ${alertMessage.author.last_name}</strong>: ${alertMessage.message}`,
      closable: true,
      id: alertMessage.id
    })) ?? []);
    this.loadingMessages.set(false);
  }

  async markAsSeen(msg: Message) {
    await this.messagesService.markAsSeen(msg.id);
    void this.loadMessages();
  }
}
